<template>
  <div>
    <Top title="提交结果" />
    <div class="resut bg-block mt54">
      <a-result
        status="success"
        title="提交成功"
        sub-title="稍后会有工作人员联系您，请保持电话畅通"
      >
      </a-result>
    </div>
  </div>
</template>
<script>
import { Result } from "ant-design-vue";
import Top from "@/components/top/top";
export default {
  components: {
    AResult: Result,
    Top,
  },
  methods: {},
};
</script>